<template>
  <v-content>
    <div class="page">
      <v-progress-linear
        indeterminate
        color="warning"
        v-if="carregando"
      ></v-progress-linear>
      <div class="imgweb d-none d-sm-none d-md-block" style="overflow-y: auto !important;">
        <v-container fill-height>
          <v-row align="center" justify="start">
            <v-col cols="6">
              <span id="tocopy" class="text-h5" style="color: #8BCD9E;" data-toggle="tooltip" title="Copiado!" @onclick="tocopy()"><strong>escritora@danielalaube.com.br</strong></span>
              <v-img width="300" height="300" class="ml-5" :src="require('../assets/img/qrcodepixinfantil.png')"></v-img>
            </v-col>
            <v-col cols="6" class="pl-10 pt-0 mt-5">
              <v-row>
                <span class="text-h6 white--text">Enviar comprovante:</span>
              </v-row>
              <v-form method="post" action="" id="needs-validation" novalidate>
                <v-row class="mt-2">
                  <div class="col-12">
                    <div class="input-group input-group-sm">
                      <span class="white--text input-group-text" id="inputGroup-nome">Nome:*</span>
                      <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputNome" class="form-control" placeholder="Digite o seu nome" aria-label="Digite seu nome" aria-describedby="inputGroup-nome" required></v-text-field>
                    </div>
                  </div>
                  <div class="col-12 mt-0 pt-0">
                    <div class="input-group input-group-sm">
                      <span class="white--text input-group-text" id="inputGroup-telefone">Telefone:*</span>
                      <v-text-field :rules="[rules.required]" flat solo dense v-model="inputTelefone" v-mask="'(##) #####-####'" type="text" id="telefone" class="form-control" placeholder="(31) 98765-4321" aria-label="Digite seu telefone" aria-describedby="inputGroup-telefone" required></v-text-field>
                    </div>
                  </div>
                  <div class="col-8 mt-0 pt-0">
                    <div class="input-group input-group-sm">
                      <span class="white--text input-group-text" id="inputGroup-rua">Rua:*</span>
                      <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputRua" class="form-control" placeholder="Digite a rua" aria-label="Digite a rua" aria-describedby="inputGroup-rua" required></v-text-field>
                    </div>
                  </div>
                  <div class="col-4 mt-0 pt-0">
                    <div class="input-group input-group-sm pl-1">
                      <span class="white--text input-group-text" id="inputGroup-numero">Nº:*</span>
                      <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputNumero" class="form-control" placeholder="123" aria-label="Digite o numero" aria-describedby="inputGroup-numero" required></v-text-field>
                    </div>
                  </div>
                  <div class="col-8 mt-0 pt-0">
                    <div class="input-group input-group-sm">
                      <span class="white--text input-group-text" id="inputGroup-bairro">Bairro:*</span>
                      <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputBairro" class="form-control" placeholder="Digite o bairro" aria-label="Digite o bairro" aria-describedby="inputGroup-bairro" required></v-text-field>
                    </div>
                  </div>
                  <div class="col-4 mt-0 pt-0">
                    <div class="input-group input-group-sm pl-1">
                      <span class="white--text input-group-text" id="inputGroup-comp">Comp:</span>
                      <v-text-field flat solo dense type="text" v-model="inputComplemento" class="form-control" placeholder="202" aria-label="Digite o complemento" aria-describedby="inputGroup-comp"></v-text-field>
                    </div>
                  </div>
                  <div class="col-12 mt-0 pt-0">
                    <span class="white--text input-group-text" id="inputGroup-comp">Foto do comprovante:</span>
                    <v-file-input
                      dense
                      flat
                      solo
                      prepend-icon=""
                      :rules="[rules.required]"
                      class="form-control form-control-sm"
                      v-model="inputFile"
                      type="file"
                      placeholder="Foto do comprovante">
                      <template v-slot:append>        
                        <v-icon style="color: #F48955;"> mdi-camera </v-icon> 
                      </template>
                      </v-file-input>
                  </div>
                  <div class="col-12">
                    <div class="input-group input-group-sm">
                      <span class="white--text input-group-text" id="inputGroup-nomeDedicatoria">Nome da criança:</span>
                      <v-text-field flat solo dense type="text" v-model="inputNomeDedicatoria" class="form-control" placeholder="Digite o nome para dedicatória" aria-label="Digite o nome para dedicatória" aria-describedby="inputGroup-nomeDedicatoria"></v-text-field>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-grid gap-2">
                      <v-btn block outlined color="success" id="submit" class="btn btn btn-outline-success" type="button" @click="submit()">ENVIAR</v-btn>
                    </div>
                  </div>
                  <v-col cols="12" class="text-center" style="cursor: pointer;" @click="comprarCartao()">
                    <span class="my-0 py-0" style="color: #8BCD9E;"><strong>Ou clique aqui para comprar com cartão</strong></span>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-row align="end" justify="end">
            <v-col cols="3" class="text-right mt-15" style="cursor: pointer;" @click="goBack()">
              <v-btn fab plain class="my-0 py-0">
                <v-img :src="require('../assets/img/laubeVoltar.png')" width="75"></v-img>
              </v-btn>
              <p class="my-0 py-0" style="color: #F48955;">Voltar</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none" style="overflow-y: auto !important;">
        <v-container fill-height>
          <v-row align="center" justify="center" class="text-center">
            <v-col cols="12">
              <v-img contain max-height="200" :src="require('../assets/img/qrcodepixinfantil.png')"></v-img>
              <span style="color: #8BCD9E;" class="text-caption">
                R$ 25,00 - use o QR code ou copie a chave email
              </span>
            </v-col>
            <v-col cols="12">
              <span id="tocopyx" class="h6" style="color: #8BCD9E;" data-toggle="tooltip" title="Copiado!" @click="tocopyx()"><strong>{{ chavePix }}</strong></span>
            </v-col>
          </v-row>
          <v-form method="post" action="" id="needs-validation" novalidate>
          <v-row class="mt-3">
            <span class="text-h6 white--text">Enviar comprovante:</span>
              <div class="col-12">
                <div>
                  <div class="input-group input-group-sm my-0 py-0">
                    <span class="white--text input-group-text" id="inputGroup-nome">Nome:*</span>
                    <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputNome" class="form-control" placeholder="Digite o seu nome" aria-label="Digite seu nome" aria-describedby="inputGroup-nome" required></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-12 my-0 py-0">
                <div>
                  <div class="input-group input-group-sm my-0 py-0">
                    <span class="white--text input-group-text" id="inputGroup-telefone">Telefone:*</span>
                    <v-text-field :rules="[rules.required]" flat solo dense v-model="inputTelefone" v-mask="'(##) #####-####'" type="text" id="telefone" class="form-control" placeholder="(31) 98765-4321" aria-label="Digite seu telefone" aria-describedby="inputGroup-telefone" required></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-8 my-0 py-0">
                <div>
                  <div class="input-group input-group-sm my-0 py-0">
                    <span class="white--text input-group-text" id="inputGroup-rua">Rua:*</span>
                    <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputRua" class="form-control" placeholder="Digite a rua" aria-label="Digite a rua" aria-describedby="inputGroup-rua" required></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-4 my-0 py-0">
                <div>
                  <div class="input-group input-group-sm pl-1 my-0 py-0">
                    <span class="white--text input-group-text" id="inputGroup-numero">Nº:*</span>
                    <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputNumero" class="form-control" placeholder="123" aria-label="Digite o numero" aria-describedby="inputGroup-numero" required></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-8 my-0 py-0">
                <div>
                  <div class="input-group input-group-sm my-0 py-0">
                    <span class="white--text input-group-text" id="inputGroup-bairro">Bairro:*</span>
                    <v-text-field :rules="[rules.required]" flat solo dense type="text" v-model="inputBairro" class="form-control" placeholder="Digite o bairro" aria-label="Digite o bairro" aria-describedby="inputGroup-bairro" required></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-4 my-0 py-0">
                <div>
                  <div class="input-group input-group-sm pl-1 my-0 py-0">
                    <span class="white--text input-group-text" id="inputGroup-comp">Comp:</span>
                    <v-text-field flat solo dense type="text" v-model="inputComplemento" class="form-control" placeholder="202" aria-label="Digite o complemento" aria-describedby="inputGroup-comp"></v-text-field>
                  </div>
                </div>
              </div>
              <div class="col-12 my-0 py-0">
                <div>
                  <span class="white--text input-group-text" id="inputGroup-comp">Foto do comprovante:</span>
                  <v-file-input
                    dense
                    flat
                    solo
                    prepend-icon=""
                    class="form-control form-control-sm"
                    v-model="inputFile"
                    type="file"
                    placeholder="Foto do comprovante">
                    <template v-slot:append>        
                      <v-icon style="color: #F48955;"> mdi-camera </v-icon> 
                    </template>
                    </v-file-input>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group input-group-sm">
                  <span class="white--text input-group-text" id="inputGroup-nomeDedicatoria">Nome da criança:</span>
                  <v-text-field flat solo dense type="text" v-model="inputNomeDedicatoria" class="form-control" placeholder="Digite o nome para dedicatória" aria-label="Digite o nome para dedicatória" aria-describedby="inputGroup-nomeDedicatoria"></v-text-field>
                </div>
              </div>
              <div class="col-12">
                <div class="d-grid gap-2">
                  <v-btn block outlined elevation="2" color="white" id="submit" type="button" @click="submit()">ENVIAR</v-btn>
                </div>
              </div>
            </v-row>
            <v-row align="start" justify="center">
              <v-col cols="6" class="text-center" style="cursor: pointer;" @click="comprarCartao()">
                <span class="my-0 py-0" style="color: #8BCD9E;"><strong>Ou clique aqui para comprar com cartão</strong></span>
              </v-col>
            </v-row>
            <v-row align="start" justify="end">
              <v-col cols="3" class="text-right mt-3" style="cursor: pointer;" @click="goBack()">
                <v-btn fab plain class="my-0 py-0">
                  <v-img :src="require('../assets/img/laubeVoltar.png')" width="75"></v-img>
                </v-btn>
                <span class="my-0 py-0" style="color: #F48955;">Voltar</span>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </div>
    <v-overlay :value="carregando">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-content>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
export default {
  name: 'HojeNaoPodeBrincarLaForaPix',
  data () {
    return {
      chavePix: 'escritora@danielalaube.com.br',
      url: 'https://www.danilaube.com.br/MLB-2110468587-hoje-no-pode-brincar-la-fora-daniela-laube-_JM',
      dirr: './pix.html',
      inputNome: '',
      inputTelefone: '',
      inputRua: '',
      inputNumero: '',
      inputBairro: '',
      inputComplemento: '',
      inputFile: null,
      inputNomeDedicatoria: '',
      rules: {
          required: value => !!value || 'Obrigatório.',
      },
      carregando: false,
    }
  },
  methods: {
    fire () {
      window.location.href = "https://www.danilaube.com.br/MLB-2110468587-hoje-no-pode-brincar-la-fora-daniela-laube-_JM";
    },
    tocopy () {
      // var range, selection;
      // if (window.getSelection && document.createRange) {
      //     selection = window.getSelection();
      //     range = document.createRange();
      //     range.selectNodeContents(this[0]);
      //     selection.removeAllRanges();
      //     selection.addRange(range);
      // } else if (document.selection && document.body.createTextRange) {
      //     range = document.body.createTextRange();
      //     range.moveToElementText(this[0]);
      //     range.select();
      // }
      try {
        var copyText = this.chavePix;
      } catch (error) {
        console.log(error)
      } finally {
        $('[data-toggle="tooltip"]').show();
        navigator.clipboard.writeText(copyText);
      } 
    },
    tocopyx () {
      // var range, selection;
      // if (window.getSelection && document.createRange) {
      //     selection = window.getSelection();
      //     range = document.createRange();
      //     range.selectNodeContents(this[0]);
      //     selection.removeAllRanges();
      //     selection.addRange(range);
      // } else if (document.selection && document.body.createTextRange) {
      //     range = document.body.createTextRange();
      //     range.moveToElementText(this[0]);
      //     range.select();
      // }
      try {
        var copyText = this.chavePix;
      } catch (error) {
        console.log(error)
      } finally {
        $('[data-toggle="tooltip"]').show();
        navigator.clipboard.writeText(copyText);
      }
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    submit () {
      this.carregando = true;
      var nome, endereco, telefone, nomeDedicatoria;
      'use strict'
      nome = this.inputNome;
      endereco = this.inputRua + '-' + this.inputNumero + '-' + this.inputBairro + '-' + this.inputComplemento;
      telefone = this.inputTelefone;
      nomeDedicatoria = this.inputNomeDedicatoria;
      var img = this.inputFile;
      var formData = null;
      if (nome !== '' && nome !== null && telefone !== '' && telefone !== null && endereco !== '' && endereco !== null) {
        if (img) {
          this.getBase64(img)
            .then(img => {
              formData = new FormData();
              formData.append("Nome", nome);
              formData.append("Endereco", endereco);
              formData.append("Telefone", telefone);
              formData.append("NomeDedicatoria", nomeDedicatoria);
              formData.append("Tipo", "criancas");
              formData.append("ComprovanteImg", img);
              axios.post('https://laubeapi.azurewebsites.net/api/Envio', formData, {
              // axios.post('https://localhost:44303/api/Envio', formData, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*"
                  }
                })
                .then(() => {
                  this.carregando = false;
                  alert('Obrigado pela compra! Recebemos seu endereço!')
                  window.location.href = "./";
                })
                .catch((e) => {
                  console.log(e);
                })
            })
        } else {
            formData = new FormData();
            formData.append("Nome", nome);
            formData.append("Endereco", endereco);
            formData.append("Telefone", telefone);
            formData.append("NomeDedicatoria", nomeDedicatoria);
            formData.append("Tipo", "criancas");
            axios.post('https://laubeapi.azurewebsites.net/api/Envio', formData, {
            // axios.post('https://localhost:44303/api/Envio', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*"
              }
            })
            .then(() => {
              this.carregando = false;
              alert('Obrigado pela compra! Recebemos seu endereço!')
              window.location.href = "./";
            })
            .catch((e) => {
              console.log(e);
            })
        }
      }
    },
    goBack () {
      window.location.href = './livroHojeNaoPodeBrincarLaFora'
    },
    comprarCartao () {
      var target = '_blank'
      var link = 'https://danilaube.com.br'
      window.open(
        link,
        target
      );
    }
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/hojenaopodebrincarfndpc.png');
      background-color: #cccccc;
      background-size: cover;
      min-height: 100%;
      max-height: 100%;
      height: 100vh;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/hojenaopodebrincarfndcel.png');
      background-color: #000000;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .invalid-feedback {
      margin: -1.1vh 0 0 0;
      padding: 0;
    }
</style>
